import React from 'react'
import { Grid } from '@react-md/utils'
import { Chip, Text } from 'react-md'

import { Cell } from 'components/core'

interface ComponentProps {
  sellers: Array<string>
}

const Component: React.FC<ComponentProps> = ({ sellers }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Vendedores Responsáveis
        </Text>
      </Cell>
      <Cell>
        {sellers.length === 0 && (
          <Chip noninteractable theme='outline' style={{ margin: '0.1rem' }}>
            Nenhum vendedor responsável
          </Chip>
        )}
        {sellers.length !== 0 &&
          sellers.map(name => (
            <Chip noninteractable theme='outline' style={{ margin: '0.1rem' }}>
              {name}
            </Chip>
          ))}
      </Cell>
    </Grid>
  )
}

export default Component
