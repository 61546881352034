import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getExpeditionFmMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.expedition.cubage.manual || permissions?.expedition.cubage.validate || permissions?.expedition.print.collectionProtocol) {
    menu['/expedition'] = getMenu('/expedition', null, 'Expedição', 'trolley', null)

    if (permissions?.expedition.cubage.manual || permissions?.expedition.cubage.validate) {
      menu['/expedition/cubage'] = getMenu('/expedition/cubage', '/expedition', 'Cubagem', 'square_foot', null)

      if (permissions?.expedition.cubage.manual)
        menu['/expedition/cubage/manual'] = getMenu('/expedition/cubage/manual', '/expedition/cubage', 'Manual', 'pinch', '/expedition/cubage/manual')

      if (permissions?.expedition.cubage.validate)
        menu['/expedition/cubage/validate'] = getMenu(
          '/expedition/cubage/validate',
          '/expedition/cubage',
          'Validação',
          'price_check',
          '/expedition/cubage/validate',
        )
    }

    if (permissions?.expedition.print.collectionProtocol) {
      menu['/expedition/print'] = getMenu('/expedition/print', '/expedition', 'Imprimir', 'print', null)

      if (permissions?.expedition.print.collectionProtocol)
        menu['/expedition/print/collection-protocol'] = getMenu(
          '/expedition/print/collection-protocol',
          '/expedition/print',
          'Protocolo de Coleta',
          'receipt_long',
          '/expedition/print/collection-protocol',
        )
    }
  }
}
