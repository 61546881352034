import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Grid, useToggle } from '@react-md/utils'
import { Card, CardActions, CardContent, FontIcon, ListItem, TabPanel, TabPanels, Tabs, TabsManager } from 'react-md'
import { toast } from 'react-toastify'
import { useReactToPrint } from 'react-to-print'

import { Actions, ButtonEdit, ButtonLog, ButtonSuccess, Cell, ConfirmDialog, PageTitle } from 'components/core'
import { addressDefaultValues, addressValidationSchema } from 'components/address/config'
import { handlePrintProtocol } from 'pages/order/protocol/print/actions'
import { PermissionDescriptionEnum } from 'types/system/permission'
import { FiscalDocumentEnum } from 'types/order/fiscal-document'
import PrintProtocol from 'pages/order/protocol/print'
import { useAuth } from 'contexts/auth/auth-context'
import { ValidationMessage } from 'types/core/api'
import { StatusEnum } from 'types/order/status'

import { taxInformationDefaultValues, taxInformationValidationSchema } from './components/tax-information/config'
import { observationDefaultValues, observationValidationSchema } from './components/observation/config'
import { recipientDefaultValues, recipientValidationSchema } from './components/recipient/config'

import { handleDelete, handleGet, handleSave, handleValidateForms, handleUpdatePrintLabel } from './actions'

import OperationalInformation from './components/operational-information'
import PartnerInformation from './components/partner-information'
import Recipient from './components/recipient'
import RecipientAddress from './components/recipient-address'
import TaxInformation from './components/tax-information'
import FinancialInformation from './components/financial-information'
import DeliveryInformation from './components/delivery-information'
import Observation from './components/observation'
import Ticket from './components/ticket'
import Tracking from './components/tracking'
import ChangeService from './components/change-service'
import UpdateDeliveryInformation from './components/update-delivery-information'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { getUserPermission } = useAuth()
  const [visibleChangeServiceModal, enableChangeServiceModal, disableChangeServiceModal] = useToggle(false)
  const [visibleUpdateDeliveryInformationModal, enableUpdateDeliveryInformationModal, disableUpdateDeliveryInformationModal] = useToggle(false)
  const recipientForm = useForm({ defaultValues: recipientDefaultValues })
  const recipientAddressForm = useForm({ defaultValues: addressDefaultValues })
  const taxInformationForm = useForm({ defaultValues: taxInformationDefaultValues })
  const observationForm = useForm({ defaultValues: observationDefaultValues })
  const [order, setOrder] = useState<any>()
  const [loadTrackings, setLoadTrackings] = useState<boolean>(false)
  const [loadTickets, setLoadTickets] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [printProtocol, setPrintProtocol] = useState<any>()
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [visibleDelete, enableDelete, disableDelete] = useToggle(false)
  const [visibleUpdatePrintLabel, enableUpdatePrintLabel, disableUpdatePrintLabel] = useToggle(false)
  const componentRef = useRef(null)

  useEffect(() => {
    document.title = 'Edição de Pedido'

    load()

    return () => {}
  }, [id])

  useEffect(() => {
    if (activeTab === 1) setLoadTrackings(true)
    else if (activeTab === 2) setLoadTickets(true)

    return () => {}
  }, [activeTab])

  const showTicketTab = getUserPermission().some(x => x === PermissionDescriptionEnum.Sac)

  const load = async () => {
    const res = await handleGet(id)
    if (res) {
      setOrder(res)
      recipientForm.reset({
        name: res.recipient.name ? res.recipient.name : null,
        document: res.recipient.document ? res.recipient.document : null,
        ie: res.recipient.ie ? res.recipient.ie : null,
        email: res.recipient.email ? res.recipient.email : null,
        phone: res.recipient.phone ? res.recipient.phone : null,
      })
      recipientAddressForm.reset(res.recipient.address)
      taxInformationForm.reset({
        fiscalNoteAccessKey: res.fiscalNoteAccessKey ? res.fiscalNoteAccessKey : null,
      })
      observationForm.reset({ observation: res.observation ? res.observation : null })
    }
  }

  const print = async () => {
    const res = await handlePrintProtocol(id)
    if (res) setPrintProtocol(res)
  }

  const handlePrintHTML = useReactToPrint({ content: () => componentRef.current, documentTitle: 'Protocolo' })

  const formIsDirty = (): boolean => {
    return (
      !recipientForm.formState.dirty &&
      !recipientAddressForm.formState.dirty &&
      !taxInformationForm.formState.dirty &&
      !observationForm.formState.dirty
    )
  }

  const formsIsValid = async (): Promise<boolean> => {
    if (order.fiscalDocument === FiscalDocumentEnum.FiscalNote && !formFiscalNoteIsValid()) return false

    return await handleValidateForms(
      { form: recipientForm, validationSchema: recipientValidationSchema },
      { form: recipientAddressForm, validationSchema: addressValidationSchema },
      { form: taxInformationForm, validationSchema: taxInformationValidationSchema },
      { form: observationForm, validationSchema: observationValidationSchema },
    )
  }

  const formFiscalNoteIsValid = (): boolean => {
    let taxValues = taxInformationForm.getValues()

    taxInformationForm.clearError('fiscalNoteAccessKey')

    if (!taxValues.fiscalNoteAccessKey) taxInformationForm.setError('fiscalNoteAccessKey', 'required', 'Campo obrigatório')

    if (Object.keys(taxInformationForm.errors).length) {
      toast.warn(ValidationMessage)
      return false
    }

    return true
  }

  const getOrderObj = () => {
    return {
      volumeId: id,
      ...taxInformationForm.getValues(),
      recipient: {
        ...recipientForm.getValues(),
        address: recipientAddressForm.getValues(),
      },
      ...observationForm.getValues(),
    }
  }

  const resetForms = () => {
    recipientForm.reset(recipientForm.getValues(), { dirty: false })
    recipientAddressForm.reset(recipientAddressForm.getValues(), { dirty: false })
    taxInformationForm.reset(taxInformationForm.getValues(), { dirty: false })
    observationForm.reset(observationForm.getValues(), { dirty: false })
  }

  return (
    <>
      <Grid>
        <Cell>
          {order && (
            <>
              <PageTitle title='Edição de Pedido' />
              <TabsManager
                tabs={showTicketTab ? ['Pedido', 'Trackings', 'Tickets'] : ['Pedido', 'Trackings']}
                activeIndex={activeTab}
                onActiveIndexChange={activeIndexNumber => setActiveTab(activeIndexNumber)}
                tabsId={'tab-order'}>
                <Tabs />
                <TabPanels persistent={true}>
                  <TabPanel>
                    <Card fullWidth>
                      <CardContent>
                        <OperationalInformation order={order} />
                        <PartnerInformation order={order} />
                        <Recipient form={recipientForm} />
                        <RecipientAddress form={recipientAddressForm} />
                        <TaxInformation order={order} form={taxInformationForm} />
                        <FinancialInformation order={order} />
                        <DeliveryInformation order={order} print={print} />
                        <Observation form={observationForm} />
                      </CardContent>
                      <CardActions align='end'>
                        <ButtonSuccess
                          text='Salvar'
                          icon={<FontIcon>done</FontIcon>}
                          disabled={formIsDirty()}
                          onClick={async () => {
                            if (await formsIsValid()) if (await handleSave(getOrderObj())) resetForms()
                          }}
                        />
                        <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} />
                        <ButtonLog entity='Order' id={order.orderId} />
                      </CardActions>
                    </Card>
                  </TabPanel>
                  <TabPanel>{loadTrackings && <Tracking volumeId={id} />}</TabPanel>
                  {showTicketTab && <TabPanel>{loadTickets && <Ticket orderId={order.orderId} />}</TabPanel>}
                </TabPanels>
              </TabsManager>
            </>
          )}
        </Cell>
      </Grid>

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem
          leftAddon={<FontIcon>published_with_changes</FontIcon>}
          disabled={order?.status !== StatusEnum.Created && order?.status !== StatusEnum.TransferBetweenUnits}
          onClick={() => enableChangeServiceModal()}>
          Alterar Serviço
        </ListItem>
        <ListItem
          leftAddon={<FontIcon>edit_location</FontIcon>}
          disabled={!order?.deliveryInformation}
          onClick={() => enableUpdateDeliveryInformationModal()}>
          Alterar Informações de Entrega
        </ListItem>
        <ListItem leftAddon={<FontIcon>delete</FontIcon>} disabled={order?.status !== StatusEnum.Created} onClick={() => enableDelete()}>
          Excluir Volume
        </ListItem>
        <ListItem leftAddon={<FontIcon>print</FontIcon>} disabled={!order?.printedLabel} onClick={() => enableUpdatePrintLabel()}>
          Reimpressão de Etiqueta
        </ListItem>
      </Actions>

      {visibleChangeServiceModal && <ChangeService orderId={order.orderId} disableModal={disableChangeServiceModal} loadOrder={load} />}

      {visibleUpdateDeliveryInformationModal && (
        <UpdateDeliveryInformation
          volumeId={id}
          deliveryInformation={order.deliveryInformation}
          disableModal={disableUpdateDeliveryInformationModal}
          loadOrder={load}
        />
      )}

      {printProtocol && (
        <div style={{ display: 'none' }}>
          <PrintProtocol protocol={printProtocol} handlePrint={handlePrintHTML} ref={componentRef} />
        </div>
      )}

      <ConfirmDialog
        text='Deseja realmente incluir o volume na fila de processamento de exclusão? Essa ação não poderá ser desfeita'
        visible={visibleDelete}
        confirmAction={async () => {
          if (
            await handleDelete({
              orderId: order.orderId,
              volumeId: id,
            })
          ) {
            const newWindow = window.open('', '_self')
            window.close()
            newWindow?.close()
          }
          disableDelete()
        }}
        cancelAction={async () => disableDelete()}
      />

      <ConfirmDialog
        text='Deseja realmente permitir a reimpressão de etiqueta do volume? Essa ação não poderá ser desfeita'
        visible={visibleUpdatePrintLabel}
        confirmAction={async () => {
          await handleUpdatePrintLabel({
            trackingCode: order.trackingCode,
            volumeId: order.volumeId,
            reprint: true,
          })
          disableUpdatePrintLabel()
        }}
        cancelAction={async () => disableUpdatePrintLabel()}
      />
    </>
  )
}

export default Component
