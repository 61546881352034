import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, Text, FontIcon, useToggle } from 'react-md'
import { toast } from 'react-toastify'

import { Cell, PageTitle, ButtonSuccess, ConfirmDialog } from 'components/core'
import { handleSubmit, InputArea } from 'components/form'
import sanitizeText from 'utils/sanitize-text'

import { defaultValues, validationSchema } from './config'
import { handleDelete } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [visibleDelete, enableDelete, disableDelete] = useToggle(false)

  useEffect(() => {
    document.title = 'Exclusão de Pedidos'

    return () => {}
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Exclusão de Pedidos' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <InputArea name='trackings' label='Trackings' rows={6} maxRows={6} desktopSize={6} tabletSize={8} />
                  <Cell>
                    <Text type='caption' component='h1'>
                      <i>Para realizar a exclusão informe no máximo de 1000 trackings</i>
                    </Text>
                  </Cell>
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Excluir'
                icon={<FontIcon>delete</FontIcon>}
                disabled={!form.formState.dirty}
                onClick={async () => enableDelete()}
              />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      <ConfirmDialog
        text='Deseja realmente excluir os pedidos? Essa ação não poderá ser desfeita'
        visible={visibleDelete ? true : false}
        confirmAction={async () => {
          disableDelete()
          await handleSubmit(form, validationSchema, async () => {
            let formValues = form.getValues()
            let values: Array<string> = []

            if (formValues.trackings) {
              let itens = formValues.trackings as string
              itens.split('\n').forEach((value: string) => {
                let valueSanitezed = sanitizeText(value)
                if (valueSanitezed) values.push(valueSanitezed)
              })

              if (values.length > 5000) {
                toast.warn(`Não é permitido pesquisar mais de 1000 trackings`)
                return
              }

              await handleDelete({ trackings: values })

              form.reset(defaultValues, { dirty: false })
            }
          })
        }}
        cancelAction={async () => disableDelete()}
      />
    </>
  )
}

export default Component
