import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import ExpeditionCubageManual from 'pages/expedition/cubage/manual'
import ExpeditionCubageValidate from 'pages/expedition/cubage/validate'

import ExpeditionPrintCollectionProtocol from 'pages/expedition/print/collection-protocol'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.expedition.cubage.manual && (
        <>
          <Route path='/expedition/cubage/manual' exact component={ExpeditionCubageManual} />
        </>
      )}

      {menuPermission?.expedition.cubage.validate && (
        <>
          <Route path='/expedition/cubage/validate' exact component={ExpeditionCubageValidate} />
        </>
      )}

      {menuPermission?.expedition.print.collectionProtocol && (
        <>
          <Route path='/expedition/print/collection-protocol' exact component={ExpeditionPrintCollectionProtocol} />
        </>
      )}
    </>
  )
}

export default Routes
