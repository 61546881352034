export const getIssuanceCteDescription = (status: string): string => {
  return IssuanceCteOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface IssuanceCteList {
  value: IssuanceCteEnum | null
  label: string
}

export enum IssuanceCteEnum {
  Client = 'Client',
  Partner = 'Partner',
  ClientAndPartner = 'ClientAndPartner',
}

export const IssuanceCteOptions: Array<IssuanceCteList> = [
  { value: null, label: '' },
  { value: IssuanceCteEnum.Client, label: 'Cliente' },
  { value: IssuanceCteEnum.Partner, label: 'Parceiro' },
  { value: IssuanceCteEnum.ClientAndPartner, label: 'Cliente e Parceiro' },
]
