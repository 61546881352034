import api from 'services/integration/webhook'

export const handleSendTest = async (request: any) => {
  let res = await api.sendTest(request)

  return res?.data ?? []
}

export const downloadJsonFileModel = () => {
  const url = window.URL.createObjectURL(
    new Blob([
      JSON.stringify(
        {
          TrackingCode: '000000AAAAAAAAAAAA',
          VolumeId: '111111BBBBBBBBBBBB',
          OrderNumber: '222222',
          FiscalNoteNumber: '333333',
          FiscalNoteAccessKey: '00000000000000000000000000000000000000000000',
          PostOfficeTracking: 'PC123456789BR',
          DeliveryInformation: {
            ReceivedBy: 'Fm Transportes',
          },
          Trackings: [
            {
              Date: '2025-02-17T08:00:00-03:00',
              Status: 0,
            },
            {
              Date: '2025-02-17T11:00:00-03:00',
              Status: 83,
            },
            {
              Date: '2025-02-17T14:00:00-03:00',
              Status: 106,
            },
            {
              Date: '2025-02-18T10:00:00-03:00',
              Status: 104,
            },
            {
              Date: '2025-02-18T12:00:00-03:00',
              Status: 1,
            },
          ],
        },
        null,
        '    ',
      ),
    ]),
  )
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'request.json')
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}
