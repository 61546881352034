import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, TableRow, TableCell } from 'react-md'

import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { PartnerAutoComplete } from 'components/auto-complete'
import { DataTableResult } from 'components/data-table/types'
import { handleSubmit, Input } from 'components/form'
import { DataTable } from 'components/data-table'

import { defaultValues, validationSchema } from './config'
import { handleSearch } from './actions'
import { formatCurrency } from 'types/core/currency'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    document.title = 'Pesquisa de Tarifa'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Pesquisa de Tarifa' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <PartnerAutoComplete name='partner' label='Parceiro *' desktopSize={6} tabletSize={8} />
                <Input name='fare' label='Tarifa' maxLength={8} desktopSize={2} tabletSize={3} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, async () => setFilter(form.getValues()))} />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
      <Cell>
        <DataTable
          filter={filter}
          header={[
            { label: 'Tarifa' },
            { label: '0,05' },
            { label: '0,10' },
            { label: '0,25' },
            { label: '0,30' },
            { label: '0,50' },
            { label: '0,75' },
            { label: '1,00' },
            { label: '1,25' },
            { label: '1,50' },
            { label: '2,00' },
            { label: '2,50' },
            { label: '3,00' },
            { label: '3,50' },
            { label: '4,00' },
            { label: '5,00' },
            { label: '6,00' },
            { label: '7,00' },
            { label: '8,00' },
            { label: '9,00' },
            { label: '10,00' },
            { label: '11,00' },
            { label: '12,00' },
            { label: '13,00' },
            { label: '14,00' },
            { label: '15,00' },
            { label: '16,00' },
            { label: '17,00' },
            { label: '18,00' },
            { label: '19,00' },
            { label: '20,00' },
            { label: '21,00' },
            { label: '22,00' },
            { label: '23,00' },
            { label: '24,00' },
            { label: '25,00' },
            { label: '26,00' },
            { label: '27,00' },
            { label: '28,00' },
            { label: '29,00' },
            { label: '30,00' },
            { label: '31,00' },
            { label: '32,00' },
            { label: '33,00' },
            { label: '34,00' },
            { label: '35,00' },
            { label: '36,00' },
            { label: '37,00' },
            { label: '38,00' },
            { label: '39,00' },
            { label: '40,00' },
            { label: '41,00' },
            { label: '42,00' },
            { label: '43,00' },
            { label: '44,00' },
            { label: '45,00' },
            { label: '46,00' },
            { label: '47,00' },
            { label: '48,00' },
            { label: '49,00' },
            { label: '50,00' },
            { label: '51,00' },
            { label: '52,00' },
            { label: '53,00' },
            { label: '54,00' },
            { label: '55,00' },
            { label: '56,00' },
            { label: '57,00' },
            { label: '58,00' },
            { label: '59,00' },
            { label: '60,00' },
            { label: '61,00' },
            { label: '62,00' },
            { label: '63,00' },
            { label: '64,00' },
            { label: '65,00' },
            { label: '66,00' },
            { label: '67,00' },
            { label: '68,00' },
            { label: '69,00' },
            { label: '70,00' },
            { label: '71,00' },
            { label: '72,00' },
            { label: '73,00' },
            { label: '74,00' },
            { label: '75,00' },
            { label: '76,00' },
            { label: '77,00' },
            { label: '78,00' },
            { label: '79,00' },
            { label: '80,00' },
            { label: '81,00' },
            { label: '82,00' },
            { label: '83,00' },
            { label: '84,00' },
            { label: '85,00' },
            { label: '86,00' },
            { label: '87,00' },
            { label: '88,00' },
            { label: '89,00' },
            { label: '90,00' },
            { label: '91,00' },
            { label: '92,00' },
            { label: '93,00' },
            { label: '94,00' },
            { label: '95,00' },
            { label: '96,00' },
            { label: '97,00' },
            { label: '98,00' },
            { label: '99,00' },
            { label: '100,00' },
            { label: 'Adicional' },
          ]}
          result={result}
          setResult={setResult}
          search={handleSearch}
          total={result ? result.total : 0}>
          {result &&
            result.results &&
            result.results.map((data: any, index) => (
              <TableRow key={index}>
                <TableCell>{data.fareCode}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight50gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight100gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight250gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight300gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight500gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight750gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight1kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight1250gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight1500gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight2kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight2500gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight3kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight3500gr)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight4kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight5kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight6kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight7kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight8kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight9kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight10kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight11kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight12kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight13kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight14kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight15kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight16kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight17kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight18kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight19kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight20kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight21kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight22kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight23kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight24kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight25kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight26kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight27kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight28kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight29kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight30kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight31kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight32kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight33kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight34kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight35kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight36kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight37kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight38kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight39kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight40kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight41kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight42kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight43kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight44kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight45kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight46kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight47kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight48kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight49kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight50kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight51kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight52kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight53kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight54kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight55kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight56kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight57kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight58kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight59kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight60kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight61kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight62kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight63kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight64kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight65kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight66kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight67kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight68kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight69kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight70kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight71kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight72kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight73kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight74kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight75kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight76kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight77kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight78kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight79kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight80kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight81kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight82kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight83kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight84kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight85kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight86kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight87kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight88kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight89kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight90kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight91kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight92kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight93kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight94kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight95kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight96kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight97kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight98kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight99kg)}</TableCell>
                <TableCell>{formatCurrency(data.valueWeight100kg)}</TableCell>
                <TableCell>{formatCurrency(data.additionalKg)}</TableCell>
              </TableRow>
            ))}
        </DataTable>
      </Cell>
    </Grid>
  )
}

export default Component
