import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'

import { getPartnerIntegrationDescription } from 'types/quote/partner-integration'
import { NumberLabel, TextLabel } from 'components/label'
import { Cell } from 'components/core'

interface ComponentProps {
  order: any
}

const Component: React.FC<ComponentProps> = ({ order }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Informações Parceiro
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <TextLabel name='partner' label='Parceiro' value={getPartnerIntegrationDescription(order.partner)} desktopSize={3} tabletSize={8} />
          <TextLabel name='awb' label='Awb' value={order?.awb} desktopSize={3} tabletSize={4} />
          <NumberLabel name='partnerCte' label='Núm. Cte Parceiro' value={order.partnerCte} desktopSize={2} tabletSize={4} />
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
