import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/order/print'
const Api = {
  printContentDeclarationClient: (request: any): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/content-declaration/client`, request)),
  printContentDeclarationFm: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/content-declaration`, request)),
  printLabelClient: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/label/client`, request)),
  printLabelFm: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/label`, request)),
  updatePrintLabel: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(`${url}/label`, request)),
  printCollectionProtocol: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/collection-protocol`, request)),
}

export default Api
