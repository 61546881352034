import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'
import { FormContext, FormContextValues } from 'react-hook-form'

import { IssuanceCteOptions } from 'types/invoicing/issuance-cte'
import { InputDocument, Select } from 'components/form'
import { ActivityOptions } from 'types/sale/activity'
import { Input } from 'components/form'
import { Cell } from 'components/core'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <FormContext {...form}>
      <Grid>
        <Cell>
          <Text type='headline-6' margin='none'>
            Tomador
          </Text>
        </Cell>
        <Cell>
          <Grid>
            <Input name='takerName' label='Nome *' desktopSize={12} tabletSize={8} />
            <InputDocument name='takerDocument' label='Cpf/Cnpj *' maxLength={18} desktopSize={3} tabletSize={4} />
            <Input name='takerIe' label='Inscrição Estadual *' desktopSize={3} tabletSize={4} />
            <Select name='issuanceCte' label='Emissão de Cte *' options={IssuanceCteOptions} desktopSize={3} tabletSize={4} />
            <Select name='activity' label='Atividade *' options={ActivityOptions} desktopSize={3} tabletSize={4} />
            <Input name='financialInformation' label='Informações Financeiras' desktopSize={12} tabletSize={8} />
          </Grid>
        </Cell>
      </Grid>
    </FormContext>
  )
}

export default Component
