import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'
import { DataTableRequest } from 'components/data-table/types'

const url = '/v1/profile'
const Api = {
  get: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.get(`${url}/${id}`)),
  create: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(url, request)),
  addPermission: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/permission`, request)),
  addBusinessGroup: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/business-group`, request)),
  search: (request: DataTableRequest): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/search`, request)),
  profileAutocomplete: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/autocomplete`, request)),
  update: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.put(url, request)),
  delete: (id: string): Promise<ApiResponse> => trackPromise<ApiResponse>(api.delete(`${url}/${id}`)),
  deletePermission: (profileId: string, id: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${profileId}/${id}/permission`)),
  deleteBusinessGroup: (profileId: string, id: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.delete(`${url}/${profileId}/${id}/business-group`)),
}

export default Api
