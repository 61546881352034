import Yup from 'utils/yup'

export const takerDefaultValues = {
  takerName: null,
  takerDocument: null,
  takerIe: null,
  issuanceCte: null,
  activity: null,
  financialInformation: null,
}

export const takerValidationSchema = Yup.object().shape({
  takerName: Yup.string().nullable().required().max(60, 'O nome deve ter no máximo 60 caracteres'),
  takerDocument: Yup.string().nullable().required().max(14, 'O cpf/cnpj deve ter no máximo 60 caracteres'),
  takerIe: Yup.string().nullable().max(14, 'A inscrição estadual deve ter no máximo 14 caracteres'),
  issuanceCte: Yup.string().nullable().required(),
  activity: Yup.string().nullable().required(),
  financialInformation: Yup.string().nullable().max(100, 'As informações financeiras devem ter no máximo 100 caracteres'),
})
