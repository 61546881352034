import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { ButtonLog, Cell, PageTitle, ButtonSuccess } from 'components/core'
import { ProfileAutoComplete } from 'components/auto-complete'
import { handleSubmit, Input, Switch } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleGet, handleSave } from './actions'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Edição de Usuário'

    return () => {}
  }, [])

  useEffect(() => {
    const load = async () => {
      const res = await handleGet(id)
      if (res) form.reset(res)
    }

    load()

    return () => {}
  }, [id])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Edição de Usuário' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Input name='name' label='Nome *' desktopSize={6} tabletSize={4} />
                <Input name='email' label='Email *' desktopSize={6} tabletSize={4} />
              </Grid>
              <Grid>
                <ProfileAutoComplete name='profile' label='Perfil *' desktopSize={6} tabletSize={4} />
                <Switch name='active' labelTrue='Ativo' labelFalse='Inativo' defaultChecked={false} inline desktopSize={6} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Salvar'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSave({ ...form.getValues(), id: id })) form.reset(form.getValues(), { dirty: false })
                })
              }
            />
            <ButtonLog entity='User' id={id} />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
