import React from 'react'
import { format } from 'date-fns'

interface ComponentProps {
  partner: string | null
  initialReceiptDate: string | null
  finalReceiptDate: string | null
  orders: Array<any>
}

const Component = React.forwardRef<HTMLDivElement, ComponentProps>((props, ref) => {
  let { partner, initialReceiptDate, finalReceiptDate, orders } = props

  return (
    <div ref={ref}>
      <style type='text/css' media='print'>
        {'@media print {html, body {height: 100vh; margin: 0 !important; padding: 0 !important;}}'}
      </style>
      <div style={{ fontFamily: 'sans-serif', padding: '10px' }}>
        <div style={{ textAlign: 'center' }}>
          <p>FM Transportes - Protocolo de Coleta - Parceiro {partner}</p>
          <p>
            {format(new Date(initialReceiptDate!), 'dd/MM/yyyy HH:mm')} à {format(new Date(finalReceiptDate!), 'dd/MM/yyyy HH:mm')}
          </p>
        </div>

        <table style={{ width: '100%', border: '1px solid', borderCollapse: 'collapse', marginTop: '2rem', marginBottom: '2rem', fontSize: 'small' }}>
          <thead>
            <tr>
              <td style={{ textAlign: 'center' }}>Destinatário</td>
              <td style={{ textAlign: 'center' }}>Tracking</td>
              <td style={{ textAlign: 'center' }}>Id Volume</td>
            </tr>
          </thead>
          <tbody>
            {orders.map((order: any, index: number) => (
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>{order.recipientName}</td>
                <td style={{ textAlign: 'center' }}>{order.trackingCode}</td>
                <td style={{ textAlign: 'center' }}>{order.volumeId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ pageBreakBefore: 'auto', fontFamily: 'sans-serif', padding: '10px' }}>
        <br />

        <div style={{ width: '100%' }}>
          <div style={{ width: '50%', float: 'left', textAlign: 'center' }}>Data Hora: _______________________________</div>
          <div style={{ width: '50%', float: 'right', textAlign: 'center' }}>Qtd Total Vols: {orders.length}</div>
        </div>

        <br />
        <br />

        <div style={{ width: '100%' }}>
          <div style={{ width: '50%', float: 'left', textAlign: 'center' }}>Conferente FM</div>
          <div style={{ width: '50%', float: 'right', textAlign: 'center' }}>Conferente {partner}</div>
        </div>

        <br />
        <br />
        <br />

        <div style={{ width: '50%', height: '30px', float: 'left', textAlign: 'center' }}>Nome: ___________________________________</div>

        <div style={{ width: '50%', height: '30px', float: 'right', textAlign: 'center' }}>Nome: ___________________________________</div>

        <br />
        <br />
        <br />

        <div style={{ width: '50%', height: '30px', float: 'left', textAlign: 'center' }}>RG: ___________________________________</div>

        <div style={{ width: '50%', height: '30px', float: 'right', textAlign: 'center' }}>RG: ___________________________________</div>
      </div>
    </div>
  )
})

export default Component
