import { Card, CardContent, Text } from 'react-md'

import { formatCurrency } from 'types/core/currency'

interface CountProps {
  color: string
  text: string
  total: number
  percent: number
}

const Component = ({ color, text, total, percent }: CountProps) => (
  <Card style={{ borderLeft: `0.5rem solid ${color}`, marginBottom: '0.1rem', width: '100%', padding: 5 }}>
    <CardContent disablePadding disableParagraphMargin style={{ padding: 5 }}>
      <Text type='subtitle-1' align='center' style={{ marginTop: '0.1rem', marginBottom: '0.1rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}>
        {text}
      </Text>
      <Text type='headline-6' align='center' style={{ marginTop: '0', marginBottom: '0.1rem', marginLeft: '0.5rem', marginRight: '0.5rem' }}>
        {total} {`(${formatCurrency(percent)}%)`}
      </Text>
    </CardContent>
  </Card>
)

export default Component
