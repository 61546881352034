import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  email: null,
  profile: {
    id: null,
  },
  active: false,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().min(3, 'O nome deve ter no mínimo 3 caracteres').max(40, 'O nome deve ter no máximo 40 caracteres'),
  email: Yup.string().nullable().required().email('E-mail inválido').max(80, 'O email deve ter no máximo 80 caracteres'),
  profile: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  active: Yup.bool().nullable().required(),
})
