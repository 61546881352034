import Yup from 'utils/yup'

export const defaultValues = {
  token: null,
  standardServiceId: null,
  expressServiceId: null,
  medioRodoServiceId: null,
  superLightServiceId: null,
  largeFormatsServiceId: null,
  sameDayServiceId: null,
}

export const validationSchema = Yup.object().shape({
  token: Yup.string().nullable().required(),
  standardServiceId: Yup.string()
    .nullable()
    .max(15, 'O código deve ter no máximo 15 caracteres')
    .test('standard-service-id', 'Informe um código de serviço', function (value) {
      return (
        this.parent.expressServiceId ||
        this.parent.medioRodoServiceId ||
        this.parent.superLightServiceId ||
        this.parent.largeFormatsServiceId ||
        this.parent.sameDayServiceId ||
        value
      )
    }),
  expressServiceId: Yup.string()
    .nullable()
    .max(15, 'O código deve ter no máximo 15 caracteres')
    .test('express-service-id', 'Informe um código de serviço', function (value) {
      return (
        this.parent.standardServiceId ||
        this.parent.medioRodoServiceId ||
        this.parent.superLightServiceId ||
        this.parent.largeFormatsServiceId ||
        this.parent.sameDayServiceId ||
        value
      )
    }),
  medioRodoServiceId: Yup.string()
    .nullable()
    .max(15, 'O código deve ter no máximo 15 caracteres')
    .test('medio-rodo-service-id', 'Informe um código de serviço', function (value) {
      return (
        this.parent.standardServiceId ||
        this.parent.expressServiceId ||
        this.parent.superLightServiceId ||
        this.parent.largeFormatsServiceId ||
        this.parent.sameDayServiceId ||
        value
      )
    }),

  superLightServiceId: Yup.string()
    .nullable()
    .max(15, 'O código deve ter no máximo 15 caracteres')
    .test('super-light-service-id', 'Informe um código de serviço', function (value) {
      return (
        this.parent.standardServiceId ||
        this.parent.expressServiceId ||
        this.parent.medioRodoServiceId ||
        this.parent.largeFormatsServiceId ||
        this.parent.sameDayServiceId ||
        value
      )
    }),
  largeFormatsServiceId: Yup.string()
    .nullable()
    .max(15, 'O código deve ter no máximo 15 caracteres')
    .test('large-formats-service-id', 'Informe um código de serviço', function (value) {
      return (
        this.parent.standardServiceId ||
        this.parent.expressServiceId ||
        this.parent.medioRodoServiceId ||
        this.parent.superLightServiceId ||
        this.parent.sameDayServiceId ||
        value
      )
    }),
  sameDayServiceId: Yup.string()
    .nullable()
    .max(15, 'O código deve ter no máximo 15 caracteres')
    .test('same-day-service-id', 'Informe um código de serviço', function (value) {
      return (
        this.parent.standardServiceId ||
        this.parent.expressServiceId ||
        this.parent.medioRodoServiceId ||
        this.parent.superLightServiceId ||
        this.parent.largeFormatsServiceId ||
        value
      )
    }),
})
