import Yup from 'utils/yup'

export const defaultValues = {
  name: null,
  email: null,
  login: null,
  profile: {
    id: null,
  },
  password: null,
  confirmPassword: null,
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required().min(3, 'O nome deve ter no mínimo 3 caracteres').max(40, 'O nome deve ter no máximo 40 caracteres'),
  email: Yup.string().nullable().required().email('E-mail inválido').max(80, 'O email deve ter no máximo 80 caracteres'),
  login: Yup.string()
    .nullable()
    .required()
    .min(5, 'O login deve ter no mínimo 5 caracteres')
    .max(20, 'O login deve ter no máximo 20 caracteres')
    .matches(new RegExp('^[a-z0-9.]*$'), { message: 'Informe apenas letras minúsculas, números ou ponto' }),
  profile: Yup.object({
    id: Yup.string().nullable().required(),
  }),
  password: Yup.string()
    .nullable()
    .required()
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .max(40, 'A senha deve ter no máximo 40 caracteres')
    .test('passwords-match', 'A confirmação de senha está incorreta', function (value) {
      return this.parent.confirmPassword === value
    })
    .matches(new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*'), {
      message: 'Informe uma senha que contenha um ou mais números, letras maiúsculas e letras minúsculas',
    }),
  confirmPassword: Yup.string()
    .nullable()
    .required()
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .max(40, 'A senha deve ter no máximo 40 caracteres')
    .test('passwords-match', 'A confirmação de senha está incorreta', function (value) {
      return this.parent.password === value
    })
    .matches(new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*'), {
      message: 'Informe uma senha que contenha um ou mais números, letras maiúsculas e letras minúsculas',
    }),
})
