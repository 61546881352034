import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, Text } from 'react-md'
import { toast } from 'react-toastify'

import { Cell, PageTitle, ButtonSearch, ButtonClearSearch, Callout } from 'components/core'
import { handleSubmit, InputArea } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { CalloutType } from 'components/core/callout'
import sanitizeText from 'utils/sanitize-text'
import { handleProcess } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Processamento'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Processamento' />
        <Card fullWidth>
          <CardContent>
            <Callout
              text='Essa funcionalidade permite iniciar o processo de faturamento dos pedidos não faturados automaticamente'
              type={CalloutType.Info}
            />
            <FormContext {...form}>
              <Grid>
                <InputArea name='values' label='Trackings *' rows={6} maxRows={6} desktopSize={6} tabletSize={8} />
                <Cell>
                  <Text type='caption' component='h1'>
                    <i>Para realizar o processamento informe no máximo de 1.000 trackings</i>
                  </Text>
                </Cell>
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch
              text='Processar'
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  let formValues = form.getValues()
                  let values: Array<string> = []

                  if (formValues.values) {
                    let itens = formValues.values as string
                    itens.split('\n').forEach((value: string) => {
                      let valueSanitezed = sanitizeText(value)
                      if (valueSanitezed) values.push(valueSanitezed)
                    })

                    if (values.length > 1000) {
                      toast.warn(`Não é permitido processar mais de 1.000 trackings`)
                      return
                    }

                    await handleProcess({ values: values })

                    form.reset(defaultValues, { dirty: false })
                  }
                })
              }
            />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
