import React, { useEffect, useState } from 'react'
import { createContext } from 'react'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'

import { PermissionDescriptionEnum } from 'types/system/permission'
import { MenuPermission } from 'components/layout/components/menu/types'
import { history } from 'utils/history'
import Api from 'services/system/login'

import { AuthContextData, UserData, LoginRequest, Theme } from './types'

const AuthContext = createContext({} as AuthContextData)

export const AuthProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme | null>(null)
  const [user, setUser] = useState<UserData | null>(null)
  const [logged, setLogged] = useState<boolean | null>(null)
  const cookies = new Cookies()

  useEffect(() => {
    let token = cookies.get('token')
    try {
      if (token) {
        let tokenParsed = JSON.parse(atob(token.split('.')[1]))
        setUser({
          id: tokenParsed.e,
          name: tokenParsed.n,
          permissions: tokenParsed.p,
        })
        setTheme(tokenParsed.t === 'Dark' ? Theme.Dark : Theme.Light)
        setLogged(true)
      } else {
        setUser(null)
        setTheme(cookies.get('theme') === 'Dark' ? Theme.Dark : Theme.Light)
        setLogged(false)
      }
    } catch (error) {
      setUser(null)
      setLogged(false)
    }

    return () => {}
  }, [])

  useEffect(() => {
    if (theme === Theme.Dark) {
      document.getElementsByTagName('html')[0].classList.add('dark-theme')
      document.getElementsByTagName('html')[0].classList.remove('light-theme')
    } else {
      document.getElementsByTagName('html')[0].classList.add('light-theme')
      document.getElementsByTagName('html')[0].classList.remove('dark-theme')
    }

    return () => {}
  }, [theme])

  async function login(values: LoginRequest) {
    let result = await Api.login(values)
    if (result && result.data) {
      if (Array.isArray(result.data) && result.data.some(x => x.key === 'UnconfirmedEmail')) {
        result.data.map((x: any) => toast.warn(x.message))
        history.push('/send-confirm-email')
      } else if (typeof result.data === 'string') {
        let data = JSON.parse(atob(result.data.split('.')[1]))
        cookies.remove('token', { path: '/', sameSite: 'strict' })
        cookies.set('token', result.data, { path: '/', expires: new Date(data.exp * 1000) })
        cookies.set('theme', data.t, { path: '/' })
        setUser({ id: data.e, name: data.n, permissions: data.p })
        setTheme(data.t === 'Dark' ? Theme.Dark : Theme.Light)
        setLogged(true)
        toast.success('Login realizado com sucesso')
      }
    }
  }

  function logout() {
    cookies.remove('token', { path: '/', sameSite: 'strict' })
    setUser(null)
    setLogged(false)
  }

  function getUserPermission(): Array<PermissionDescriptionEnum> {
    return user ? JSON.parse(user.permissions.toString()) : []
  }

  function getMenuPermission(): MenuPermission {
    if (user?.permissions) {
      let permissions = getUserPermission()

      return {
        audit: true,
        delivery: {
          deliveryPackingList: permissions.some(x => x === PermissionDescriptionEnum.Expedition),
        },
        devolution: {
          devolutionPackingList: permissions.some(x => x === PermissionDescriptionEnum.Expedition),
        },
        expedition: {
          cubage: {
            manual: permissions.some(x => x === PermissionDescriptionEnum.Expedition),
            validate: permissions.some(x => x === PermissionDescriptionEnum.Expedition),
          },
          print: {
            collectionProtocol: permissions.some(x => x === PermissionDescriptionEnum.Expedition),
          },
        },
        integration: {
          client: permissions.some(x => x === PermissionDescriptionEnum.Client),
          bling: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
        },
        invoicing: {
          client: permissions.some(x => x === PermissionDescriptionEnum.Client),
          fm: {
            cteFix: permissions.some(x => x === PermissionDescriptionEnum.Administrator || x === PermissionDescriptionEnum.Expedition),
            process: permissions.some(x => x === PermissionDescriptionEnum.Expedition),
            report: {
              invoicingPreview: permissions.some(x => x === PermissionDescriptionEnum.Administrator || x === PermissionDescriptionEnum.Sales),
            },
          },
        },
        order: {
          client: permissions.some(x => x === PermissionDescriptionEnum.Client),
          fm: {
            changePartnerLot: permissions.some(x => x === PermissionDescriptionEnum.Coordinator),
            deleteLot: permissions.some(x => x === PermissionDescriptionEnum.Coordinator),
            importCsv: permissions.some(x => x === PermissionDescriptionEnum.Expedition || x === PermissionDescriptionEnum.Sales),
            importManual: permissions.some(x => x === PermissionDescriptionEnum.Expedition || x === PermissionDescriptionEnum.Sales),
            importXml: permissions.some(x => x === PermissionDescriptionEnum.Expedition || x === PermissionDescriptionEnum.Sales),
            fixImportValidation: permissions.some(x => x === PermissionDescriptionEnum.Expedition || x === PermissionDescriptionEnum.Sac),
            print: {
              contentDeclaration: permissions.some(x => x === PermissionDescriptionEnum.Expedition),
              label: permissions.some(x => x === PermissionDescriptionEnum.Expedition),
            },
            report: {
              incident: permissions.some(
                x =>
                  x === PermissionDescriptionEnum.Administrator ||
                  x === PermissionDescriptionEnum.Coordinator ||
                  x === PermissionDescriptionEnum.Sales,
              ),
              orders: permissions.some(
                x => x === PermissionDescriptionEnum.Expedition || x === PermissionDescriptionEnum.Sac || x === PermissionDescriptionEnum.Sales,
              ),
              ordersWithProblems: permissions.some(x => x === PermissionDescriptionEnum.Sac || x === PermissionDescriptionEnum.Sales),
              performance: permissions.some(
                x => x === PermissionDescriptionEnum.Coordinator || x === PermissionDescriptionEnum.Sac || x === PermissionDescriptionEnum.Sales,
              ),
              searchLot: permissions.some(
                x => x === PermissionDescriptionEnum.Expedition || x === PermissionDescriptionEnum.Sac || x === PermissionDescriptionEnum.Sales,
              ),
              stoppedOrders: permissions.some(x => x === PermissionDescriptionEnum.Coordinator),
            },
            search: permissions.some(
              x => x === PermissionDescriptionEnum.Expedition || x === PermissionDescriptionEnum.Sac || x === PermissionDescriptionEnum.Sales,
            ),
            updateStatus: {
              csv: permissions.some(x => x === PermissionDescriptionEnum.Coordinator),
              manual: permissions.some(x => x === PermissionDescriptionEnum.Expedition || x === PermissionDescriptionEnum.Sac),
            },
          },
          log: permissions.some(x => x === PermissionDescriptionEnum.Coordinator),
        },
        pickup: {
          pickupPackingList: permissions.some(x => x === PermissionDescriptionEnum.Expedition),
          client: permissions.some(x => x === PermissionDescriptionEnum.Client),
          fm: {
            pickupRequest: permissions.some(x => x === PermissionDescriptionEnum.Expedition),
          },
          pickupRoute: permissions.some(x => x === PermissionDescriptionEnum.Administrator || x === PermissionDescriptionEnum.Coordinator),
        },
        quote: {
          clientCoverage: {
            clientCoverage: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
            searchCoverage: permissions.some(
              x =>
                x === PermissionDescriptionEnum.Administrator ||
                x === PermissionDescriptionEnum.Expedition ||
                x === PermissionDescriptionEnum.Sac ||
                x === PermissionDescriptionEnum.Sales,
            ),
          },
          clientFare: {
            clientFare: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
            searchFare: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          },
          gris: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          partner: {
            partner: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
            searchCoverage: permissions.some(x => x === PermissionDescriptionEnum.Administrator || x === PermissionDescriptionEnum.Expedition),
            searchFare: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          },
          icms: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          iss: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          quote: {
            client: permissions.some(x => x === PermissionDescriptionEnum.Client),
            fm: permissions.some(
              x =>
                x === PermissionDescriptionEnum.Administrator ||
                x === PermissionDescriptionEnum.Expedition ||
                x === PermissionDescriptionEnum.Sac ||
                x === PermissionDescriptionEnum.Sales,
            ),
          },
        },
        sale: {
          businessGroup: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          client: {
            client: permissions.some(x => x === PermissionDescriptionEnum.Administrator || x === PermissionDescriptionEnum.Coordinator),
            search: permissions.some(
              x =>
                x === PermissionDescriptionEnum.Administrator ||
                x === PermissionDescriptionEnum.Coordinator ||
                x === PermissionDescriptionEnum.Expedition ||
                x === PermissionDescriptionEnum.Sac ||
                x === PermissionDescriptionEnum.Sales,
            ),
          },
        },
        system: {
          blockTask: permissions.some(x => x === PermissionDescriptionEnum.System),
          cronTask: permissions.some(x => x === PermissionDescriptionEnum.System),
          carrier: permissions.some(x => x === PermissionDescriptionEnum.Administrator || x === PermissionDescriptionEnum.Coordinator),
          company: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          holiday: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          ibge: permissions.some(x => x === PermissionDescriptionEnum.Administrator),
          migrationData: permissions.some(x => x === PermissionDescriptionEnum.System),
          profile: permissions.some(x => x === PermissionDescriptionEnum.Administrator || x === PermissionDescriptionEnum.Coordinator),
          user: permissions.some(x => x === PermissionDescriptionEnum.Administrator || x === PermissionDescriptionEnum.Coordinator),
        },
        ticket: {
          client: permissions.some(x => x === PermissionDescriptionEnum.Client),
          fm: permissions.some(x => x === PermissionDescriptionEnum.Sac || x === PermissionDescriptionEnum.Sales),
        },
      }
    }
    return {
      audit: false,
      delivery: {
        deliveryPackingList: false,
      },
      devolution: {
        devolutionPackingList: false,
      },
      expedition: {
        cubage: {
          manual: false,
          validate: false,
        },
        print: {
          collectionProtocol: false,
        },
      },
      integration: {
        client: false,
        bling: false,
      },
      invoicing: {
        client: false,
        fm: {
          cteFix: false,
          process: false,
          report: {
            invoicingPreview: false,
          },
        },
      },
      order: {
        client: false,
        fm: {
          changePartnerLot: false,
          deleteLot: false,
          importCsv: false,
          importManual: false,
          importXml: false,
          fixImportValidation: false,
          print: {
            contentDeclaration: false,
            label: false,
          },
          report: {
            incident: false,
            orders: false,
            ordersWithProblems: false,
            performance: false,
            searchLot: false,
            stoppedOrders: false,
          },
          search: false,
          updateStatus: {
            csv: false,
            manual: false,
          },
        },
        log: false,
      },
      pickup: {
        pickupPackingList: false,
        client: false,
        fm: { pickupRequest: false },
        pickupRoute: false,
      },
      quote: {
        clientCoverage: {
          clientCoverage: false,
          searchCoverage: false,
        },
        clientFare: {
          clientFare: false,
          searchFare: false,
        },
        gris: false,
        partner: {
          partner: false,
          searchCoverage: false,
          searchFare: false,
        },
        icms: false,
        iss: false,
        quote: {
          client: false,
          fm: false,
        },
      },
      sale: {
        businessGroup: false,
        client: {
          client: false,
          search: false,
        },
      },
      system: {
        blockTask: false,
        cronTask: false,
        carrier: false,
        company: false,
        holiday: false,
        ibge: false,
        migrationData: false,
        profile: false,
        user: false,
      },
      ticket: {
        client: false,
        fm: false,
      },
    }
  }

  return (
    <AuthContext.Provider value={{ logged, user, theme, login, logout, setTheme, getUserPermission, getMenuPermission }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext)
