import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, FontIcon, Table, TableBody, TableRow, TableCell, TableHeader, Text } from 'react-md'

import { Cell, PageTitle, ButtonSuccess, Callout, ButtonEdit } from 'components/core'
import { ClientAutoComplete } from 'components/auto-complete'
import { CalloutType } from 'components/core/callout'
import { handleSubmit } from 'components/form'

import { downloadJsonFileModel, handleSendTest } from './actions'
import { defaultValues, validationSchema } from './config'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Enviar Teste'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Enviar Teste' />
        <Card fullWidth>
          <CardContent>
            <Callout
              text='O método de teste realiza o envio de uma requisição do tipo POST na url cadastrada com as informações de entrega de um pedido com alguns eventos preenchidos para que seja possível validar a integração, no ambiente de produção recomandamos que a requisição seja recebida e respondida na sequência, será considerada falha uma requisição com o código HTTP diferente de 200 ou que leve mais que 10s para ser respondida, os eventos podem chegar aos endpoints em ordem diferente daquela do disparo e mais de uma vez, devido às novas tentativas de envio, recomendamos testá-lo com o RequestBin, esta ferramenta gera uma URL temporária onde você pode inspecionar as requisições realizadas.'
              type={CalloutType.Info}
            />
            <FormContext {...form}>
              <Grid>
                <ClientAutoComplete name='client' label='Remetente *' desktopSize={6} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Enviar Teste'
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  if (await handleSendTest(form.getValues())) form.reset(defaultValues, { dirty: false })
                })
              }
            />
            <ButtonEdit text='Download do Modelo JSON' icon={<FontIcon>download</FontIcon>} onClick={async () => downloadJsonFileModel()} />
          </CardActions>
        </Card>
      </Cell>
      <Cell>
        <Text type='headline-6' margin='none'>
          Autenticação
        </Text>
      </Cell>
      <Cell>
        <Card fullWidth>
          <CardContent>
            <Text type='body-2'>
              O endpoint deverá receber o header <b>Authorization</b> na requisição com as credenciais de autenticação, a autenticação deve ser{' '}
              <b>Basic Authentication</b>, é necessário fornecer o username e password para nosso time de vendas, a senha não deve ultrapassar 80
              caracteres de tamanho.
            </Text>
          </CardContent>
        </Card>
      </Cell>
      <Cell>
        <Text type='headline-6' margin='none'>
          Propriedades
        </Text>
      </Cell>
      <Cell>
        <Card fullWidth>
          <CardContent>
            <Table fullWidth dense>
              <TableHeader>
                <TableRow>
                  <TableCell>Propriedade</TableCell>
                  <TableCell>Descrição</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>TrackingCode</TableCell>
                  <TableCell>Código de rastreio</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>VolumeId</TableCell>
                  <TableCell>Id do volume</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>OrderNumber</TableCell>
                  <TableCell>Número do pedido</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>FiscalNoteNumber</TableCell>
                  <TableCell>Número da nota fiscal</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>FiscalNoteAccessKey</TableCell>
                  <TableCell>Chave da nota fiscal</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>PostOfficeTracking</TableCell>
                  <TableCell>Código de rastreio dos correios</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>DeliveryInformation</TableCell>
                  <TableCell>Objeto que contém as informações de entrega, a propriedade ReceivedBy contém o nome do recebedor</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Trackings</TableCell>
                  <TableCell>Array que contém os eventos ocorridos com o pedido</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Cell>
      <Cell>
        <Text type='headline-6' margin='none'>
          Lista de Status
        </Text>
      </Cell>
      <Cell>
        <Card fullWidth>
          <CardContent>
            <Table fullWidth dense>
              <TableHeader>
                <TableRow>
                  <TableCell>Código</TableCell>
                  <TableCell>Descrição</TableCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>0</TableCell>
                  <TableCell>Pedido Criado | Aguardando Postagem Remetente</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>Encomenda Entregue</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>6</TableCell>
                  <TableCell>Endereço Errado| Endereço Insuficiente | Número Não Localizado</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>10</TableCell>
                  <TableCell>Sinistro Liquidado</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>13</TableCell>
                  <TableCell>Endereço Fora do Perímetro Urbano | Zona Rural</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>14</TableCell>
                  <TableCell>Mercadoria Avariada</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>15</TableCell>
                  <TableCell>Embalagem em Análise</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>21</TableCell>
                  <TableCell>Destinatário Ausente | Local Fechado</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>25</TableCell>
                  <TableCell>Em Processo de Devolução</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>27</TableCell>
                  <TableCell>Roubo de Carga</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>29</TableCell>
                  <TableCell>Retirar Objeto nos Correios</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>30</TableCell>
                  <TableCell>Extravio de Carga</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>38</TableCell>
                  <TableCell>Encomenda Postada nos Correios</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>39</TableCell>
                  <TableCell>Destinatário Mudou-se</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>41</TableCell>
                  <TableCell>Destinatário Desconhecido</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>48</TableCell>
                  <TableCell>Problemas Diversos na Entrega</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>49</TableCell>
                  <TableCell>Área Restrita de Acesso</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>51</TableCell>
                  <TableCell>Endereço Não Visitado</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>52</TableCell>
                  <TableCell>Recusado na Entrega</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>56</TableCell>
                  <TableCell>Entrega Cancelada</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>57</TableCell>
                  <TableCell>Encomenda Aguardando Tratativa</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>58</TableCell>
                  <TableCell>Favor Desconsiderar Informação Anterior</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>61</TableCell>
                  <TableCell>Devolvida ao Remetente</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>64</TableCell>
                  <TableCell>Aguardando Reenvio | Nova Tentativa</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>83</TableCell>
                  <TableCell>Coleta Realizada</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>90</TableCell>
                  <TableCell>Encomenda Finalizada</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>92</TableCell>
                  <TableCell>Encomenda Retida para Analise | Posto Fiscal</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>93</TableCell>
                  <TableCell>Problemas Operacionais</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>95</TableCell>
                  <TableCell>Falta de Complemento Físico</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>101</TableCell>
                  <TableCell>Encomenda Despachada</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>102</TableCell>
                  <TableCell>Encomenda em Transito | Transferencia entre unidades</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>104</TableCell>
                  <TableCell>Processo de Entrega Iniciado</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>106</TableCell>
                  <TableCell>Encomenda Conferida</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>107</TableCell>
                  <TableCell>Encomenda Apreendida</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>108</TableCell>
                  <TableCell>Em Rota | Preparando para entrega</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>109</TableCell>
                  <TableCell>Devolução Recusada</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>110</TableCell>
                  <TableCell>Transferencia entre unidades</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>111</TableCell>
                  <TableCell>Devolução em andamento ao remetente</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
