import Yup from 'utils/yup'

export const defaultValues = {
  partner: null,
  initialReceiptDate: null,
  finalReceiptDate: null,
}

export const validationSchema = Yup.object().shape({
  partner: Yup.string().nullable().required(),
  initialReceiptDate: Yup.date().nullable().required(),
  finalReceiptDate: Yup.date().nullable().required(),
})
