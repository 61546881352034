import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, Text } from 'react-md'
import { toast } from 'react-toastify'

import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { handleSubmit, InputArea, Select } from 'components/form'
import sanitizeText from 'utils/sanitize-text'

import { defaultValues, validationSchema } from './config'
import { SearchLotClientFieldEnum, SearchLotClientFieldOptions } from './types'
import { handleReport } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [fieldDescription, setFieldDescription] = useState<string | null>(null)
  const field = form.watch('field')

  useEffect(() => {
    document.title = 'Busca Lote'

    return () => {}
  }, [])

  useEffect(() => {
    form.clearError()
    if (field) {
      if (field == SearchLotClientFieldEnum.OrderNumber) setFieldDescription('Pedidos *')
      else if (field == SearchLotClientFieldEnum.FiscalNoteNumber) setFieldDescription('Notas Fiscais *')
      else if (field == SearchLotClientFieldEnum.TrackingCode) setFieldDescription('Trackings *')
      else if (field == SearchLotClientFieldEnum.RecipientDocument) setFieldDescription('Documentos *')
      else if (field == SearchLotClientFieldEnum.FiscalNoteAccessKey) setFieldDescription('Chaves de Nota Fiscal *')
    } else setFieldDescription(null)

    return () => {}
  }, [field])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Busca Lote' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Select name='field' label='Filtro *' options={SearchLotClientFieldOptions} desktopSize={6} tabletSize={8} />
              </Grid>

              {fieldDescription && (
                <Grid>
                  <InputArea name='values' label={fieldDescription} rows={6} maxRows={6} desktopSize={6} tabletSize={8} />
                  <Cell>
                    <Text type='caption' component='h1'>
                      <i>Para realizar a pesquisa informe no máximo de 30.000 {fieldDescription.toLocaleLowerCase()}</i>
                    </Text>
                  </Cell>
                </Grid>
              )}
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  let formValues = form.getValues()
                  let values: Array<string> = []

                  if (formValues.values) {
                    let itens = formValues.values as string
                    itens.split('\n').forEach((value: string) => {
                      if (
                        field == SearchLotClientFieldEnum.FiscalNoteNumber ||
                        field == SearchLotClientFieldEnum.TrackingCode ||
                        field == SearchLotClientFieldEnum.RecipientDocument ||
                        field == SearchLotClientFieldEnum.FiscalNoteAccessKey
                      ) {
                        let valueSanitezed = sanitizeText(value)
                        if (valueSanitezed) values.push(valueSanitezed)
                      } else {
                        if (value) values.push(value)
                      }
                    })

                    if (values.length > 30000) {
                      toast.warn(`Não é permitido pesquisar mais de 30.000 ${fieldDescription?.toLocaleLowerCase()}`)
                      return
                    }

                    await handleReport({ field: formValues.field, values: values })

                    form.reset(defaultValues, { dirty: false })
                  }
                })
              }
            />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
