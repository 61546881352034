import { Cell } from 'components/core'

import CountPercentage from './components/count-percentage'
import CountWithPercentage from './components/count-with-percentage'
import CountWithoutPercentage from './components/count-without-percentage'

interface CountProps {
  result: any
}

const Component = ({ result }: CountProps) => (
  <>
    <Cell desktopSize={6} tabletSize={4}>
      <CountPercentage color='#3f51b5' text='Total de Volumes' total={result.totalOrders} />
    </Cell>
    <Cell desktopSize={6} tabletSize={4}>
      <CountWithoutPercentage color='#673ab7' text='Total SLA' percent={result.slaInPercentage} />
    </Cell>

    <Cell desktopSize={4} tabletSize={4}>
      <CountWithPercentage
        color='#009688'
        text='Entregas Dentro do SLA'
        total={result.totalDeliveredOnTime}
        percent={result.deliveredOnTimeInPercentage}
      />
    </Cell>
    <Cell desktopSize={4} tabletSize={4}>
      <CountWithPercentage
        color='#ff9800'
        text='Entregas Fora do SLA'
        total={result.totalDeliveredLate}
        percent={result.deliveredOnLateInPercentage}
      />
    </Cell>
    <Cell desktopSize={4} tabletSize={4}>
      <CountWithPercentage color='#795548' text='Devoluções' total={result.totalDevolution} percent={result.devolutionInPercentage} />
    </Cell>

    <Cell desktopSize={4} tabletSize={4}>
      <CountWithPercentage color='#f4511e' text='Sinistros' total={result.totalIncident} percent={result.incidentInPercentage} />
    </Cell>
    <Cell desktopSize={4} tabletSize={4}>
      <CountWithPercentage
        color='#4db6ac'
        text='Em Andamento Dentro do SLA'
        total={result.totalInProgressOnTime}
        percent={result.inProgressOnTimeInPercentage}
      />
    </Cell>
    <Cell desktopSize={4} tabletSize={4}>
      <CountWithPercentage
        color='#ffb74d'
        text='Em Andamento Fora do SLA'
        total={result.totalInProgressLate}
        percent={result.inProgressLateInPercentage}
      />
    </Cell>
  </>
)

export default Component
