import React, { useEffect, useRef, useState } from 'react'
import { Grid, Card, CardActions, CardContent, FontIcon } from 'react-md'
import { FormContext, useForm } from 'react-hook-form'
import { useReactToPrint } from 'react-to-print'
import { toast } from 'react-toastify'

import { getPartnerIntegrationDescription, PartnerIntegrationOptions } from 'types/quote/partner-integration'
import { Cell, PageTitle, ButtonEdit, ButtonSearch, ButtonClearSearch } from 'components/core'
import { handleSubmit, InputDateTimePicker, Select } from 'components/form'

import { defaultValues, validationSchema } from './config'
import { handleSearch } from './actions'

import Print from './components/print'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [orders, setOrders] = useState<Array<any>>()
  const componentRef = useRef(null)
  const partner = form.watch('partner')
  const initialReceiptDate = form.watch('initialReceiptDate')
  const finalReceiptDate = form.watch('finalReceiptDate')

  useEffect(() => {
    document.title = 'Impressão de Protocolo de Coleta'

    return () => {}
  }, [])

  const load = async () => {
    const res = await handleSearch(form.getValues())
    if (res) {
      if (res.length > 0) setOrders(res)
      else toast.info('Nenhuma informação localizada')
    }
  }

  const handlePrintHTML = useReactToPrint({ content: () => componentRef.current, documentTitle: 'Protocolo de Coleta' })

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Impressão de Protocolo de Coleta' />
          <Card fullWidth>
            <CardContent>
              <FormContext {...form}>
                <Grid>
                  <Select name='partner' label='Parceiro' options={PartnerIntegrationOptions} desktopSize={3} tabletSize={4} />
                  <InputDateTimePicker name='initialReceiptDate' label='Data de Recebimento Inicial *' desktopSize={3} tabletSize={4} />
                  <InputDateTimePicker name='finalReceiptDate' label='Data de Recebimento Final *' desktopSize={3} tabletSize={4} />
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSearch onClick={async () => await handleSubmit(form, validationSchema, async () => await load())} />
              <ButtonEdit
                text='Imprimir'
                icon={<FontIcon>print</FontIcon>}
                disabled={!orders || orders.length === 0}
                onClick={async () => handlePrintHTML()}
              />
              <ButtonClearSearch
                disabled={!form.formState.dirty}
                onClick={async () => {
                  form.reset(defaultValues, { dirty: false })
                }}
              />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      {partner && initialReceiptDate && finalReceiptDate && orders && orders.length > 0 && (
        <div style={{ display: 'none' }}>
          <Print
            partner={getPartnerIntegrationDescription(partner)}
            initialReceiptDate={initialReceiptDate}
            finalReceiptDate={finalReceiptDate}
            orders={orders}
            ref={componentRef}
          />
        </div>
      )}
    </>
  )
}

export default Component
