export const getServiceDescription = (status: string): string => {
  return ServiceOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface ServiceList {
  value: ServiceEnum | null
  label: string
}

export enum ServiceEnum {
  Standard = 'Standard',
  Express = 'Express',
  MedioRodo = 'MedioRodo',
  SuperLight = 'SuperLight',
  LargeFormats = 'LargeFormats',
  SameDay = 'SameDay',
}

export const ServiceOptions: Array<ServiceList> = [
  { value: null, label: '' },
  { value: ServiceEnum.Express, label: 'Expresso' },
  { value: ServiceEnum.LargeFormats, label: 'Grandes Formatos' },
  { value: ServiceEnum.MedioRodo, label: 'Medio Rodo' },
  { value: ServiceEnum.SameDay, label: 'Same Day' },
  { value: ServiceEnum.Standard, label: 'Standard' },
  { value: ServiceEnum.SuperLight, label: 'Super Leves' },
]
