import api from 'services/system/profile'
import { AutocompleteResult } from 'types/core/autocomplete'

export const profileAutocomplete = async (text: string): Promise<Array<AutocompleteResult>> => {
  if (!text) return []

  let res = await api.profileAutocomplete({ text })

  if (!res?.data?.success) return []

  return res?.data?.data
}
