import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, Text } from 'react-md'
import { toast } from 'react-toastify'

import { Cell, PageTitle, ButtonSuccess, Callout } from 'components/core'
import { handleSubmit, InputArea } from 'components/form'
import { CalloutType } from 'components/core/callout'
import sanitizeText from 'utils/sanitize-text'

import { defaultValues, validationSchema } from './config'
import { handleResend } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Reenvio de Eventos'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Reenvio de Eventos' />
        <Card fullWidth>
          <CardContent>
            <Callout
              text='O método de solicitação de reenvio de eventos é responsável por enviar as ocorrências de um pacote para a fila do Webhook, caso o tracking informado não seja localizado ou a tenha sido criado a mais de 180 dias a solicitação será descartada e nenhum aviso de erro será emitido.'
              type={CalloutType.Info}
            />
            <FormContext {...form}>
              <Grid>
                <InputArea name='trackingCodes' label='Trackings' rows={6} maxRows={6} desktopSize={6} tabletSize={8} />
                <Cell>
                  <Text type='caption' component='h1'>
                    <i>Para realizar o reenvio dos eventos para o webhook cadastrado informe no máximo de 100 trackings</i>
                  </Text>
                </Cell>
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Reenviar Eventos'
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  let formValues = form.getValues()
                  let values: Array<string> = []

                  if (formValues.trackingCodes) {
                    let itens = formValues.trackingCodes as string
                    itens.split('\n').forEach((value: string) => {
                      let valueSanitezed = sanitizeText(value)
                      if (valueSanitezed) values.push(valueSanitezed)
                    })

                    if (values.length > 100) {
                      toast.warn('Não é permitido reenviar mais de 100 trackings')
                      return
                    }

                    await handleResend({ trackingCodes: values, values: values })

                    form.reset(defaultValues, { dirty: false })
                  }
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
